<template>
  <section class="tariffs container" ref="tariff" v-if="data && data.length" id="tariff">
    <h2>Тарифы</h2>
    <div class="tariffs__content">
      <div class="tariffs__aside">
        <a
          v-for="(item, i) in data"
          :key="i"
          @click.prevent="setActive(item)"
          class="tariffs__item no-hover"
          :class="{ 'tariffs__item--active': isActive(item) }"
        >
          <h3 class="tariffs__item-title">{{ item.title }}</h3>
          <div class="tariffs__item-info" :class="{ 'tariffs__item-info--active': isActive(item) }">
            <span v-if="item.modul && item.modul.length">
              {{ item.modul.length | plural("модуль", "модуля", "модулей") }}
            </span>
            <span v-if="item.date">{{ item.date }} мес. доступа</span>
            <span v-if="item.bonus && item.bonus.length">
              {{ item.bonus.length | plural("бонус", "бонуса", "бонусов") }}
            </span>
          </div>
          <h3 class="tariffs__item-price">{{ item.price | formatPrice }} ₽</h3>
        </a>
      </div>
      <div v-if="active" class="tariffs__body">
        <div class="tariffs__body-left">
          <div class="tariffs__body-text">
            <h3 class="tariffs__body-text__title">{{ active.title }}</h3>
            <pre class="tariffs__body-text__subtitle">{{ active.description }}</pre>
          </div>
          <!--          <div class="tariffs__body-modules">-->
          <!--            <span class="tariffs__body-modules__subtitle" v-for="(item, i) in active.modul" :key="i">-->
          <!--              {{ item.title }}-->
          <!--            </span>-->
          <!--          </div>-->
          <div v-if="active.bonus && active.bonus.length" class="tariffs__body-bonuses">
            <h3 class="tariffs__body-bonuses__title">Бонусы</h3>
            <span class="tariffs__body-bonuses__subtitle" v-for="(item, i) in active.bonus" :key="i">
              {{ item.title }}
            </span>
          </div>
          <div class="tariffs__body-bottom">
            <div class="tariffs__body-buy">
              <div v-if="active?.promocode" class="tariffs__promo">
                <div class="tariffs__promo-content">
                  <span class="tariffs__promo-title">{{ active.promocode }}</span>
                  <div @click="togglePromo" class="tariffs__promo-icon">
                    <IconComponent name="promo-close" />
                  </div>
                </div>
              </div>
              <div class="tariffs__body-buy__text">
                <h3 class="tariffs__body-buy__text-price">{{ active.price | formatPrice }} ₽</h3>
                <h3 v-if="active?.oldPrice" class="tariffs__body-buy__text-price--old">
                  {{ active.oldPrice | formatPrice }} ₽
                </h3>
                <h4 v-if="active.date" class="tariffs__body-buy__text-month">{{ active.date }} мес.</h4>
              </div>
              <button
                v-if="active.purchased"
                :disabled="true"
                class="tariffs__body-buy__btn btn btn--main btn--md"
              >
                <span>Тариф уже приобретён</span>
              </button>
              <button
                v-else
                :disabled="!checkbox"
                @click.prevent="buyingCourse(active.id, active.promocode)"
                class="tariffs__body-buy__btn btn btn--main btn--md"
              >
                <LoadingIndicator v-show="loading" title="Загрузка..." />
                <span v-show="!loading">Приобрести курс</span>
              </button>
              <div class="tariffs__body-buy__checkbox">
                <CheckboxComponent v-model="checkbox">
                  <span>
                    Соглашаюсь с
                    <router-link :to="{ name: 'privacy-policy' }"> политикой конфиденциальности </router-link>
                    и
                    <router-link :to="{ name: 'public-offer' }">публичной офертой </router-link>
                  </span>
                </CheckboxComponent>
              </div>
            </div>
          </div>
        </div>
        <div class="tariffs__body-right">
          <div class="tariffs__body-questions">
            <h3 class="tariffs__body-questions__title">Есть вопросы?</h3>
            <span class="tariffs__body-questions__subtitle">
              Звоните нам
              <a
                class="tariffs__body-questions__subtitle--phone"
                v-if="phone"
                :href="('tel:' + phone) | formatNumber"
              >
                {{ phone }}
              </a>
              по будням с 12:00 до 18:00 Мск. Звонок бесплатный. Или оставьте заявку на консультацию.
            </span>
            <div class="tariffs__body-questions__btn">
              <button class="btn btn--outline btn--md" @click="openConsultationModal">
                Проконсультироваться
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ConsultationModal from "components/modals/components/ConsultationModal.vue";
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";
import LoginModal from "components/modals/components/LoginModal.vue";
import POST from "gql/mutations/OrderCreate.graphql";
import LoadingIndicator from "components/LoadingIndicator.vue";

export default {
  name: "CatalogTariffsComponent",
  components: { LoadingIndicator, CheckboxComponent, IconComponent },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkbox: true,
      active: undefined,
      loading: false,
    };
  },
  mounted() {
    this.active = this.data[0];
  },
  methods: {
    togglePromo() {
      if (this.$store.state.search_promocode.exists) {
        this.data.forEach((item) => {
          if (this.$store.state.search_promocode.tariffs.includes(item.id)) {
            item.price = item.oldPrice;
            item.oldPrice = null;
            item.promocode = null;
          }
        });
        this.$store.state.search_promocode.exists = false;
        this.$store.state.search_promocode.value = null;
      }
    },
    buyingCourse(tariff_id, promocode) {
      if (!this.loading) {
        this.loading = true;
        if (this.$store.state.user) {
          this.$apollo.provider.defaultClient
            .mutate({
              mutation: POST,
              variables: {
                promocode: promocode ? promocode : null,
                id: tariff_id,
              },
              context: {
                headers: {
                  Authorization: "Bearer " + this.$store.state.apollo_token,
                },
              },
            })
            .then(({ data }) => {
              location.replace(data.OrderCreate.payment_url);
              this.loading = false;
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
            });
        } else {
          this.loading = false;
          this.$store.state._modals.push({
            component: LoginModal,
          });
        }
      }
    },
    openConsultationModal() {
      this.$store.state._modals.push({
        component: ConsultationModal,
      });
    },
    setActive(item) {
      this.active = item;
    },
    isActive(item) {
      return JSON.stringify(item) === JSON.stringify(this.active);
    },
  },
  computed: {
    // tariffs() {
    //   return this.$store.state.tariffs;
    // },
    phone() {
      return this.$store.state.info?.phone;
    },
  },
};
</script>

<style lang="stylus">
.tariffs {
  display flex
  flex-direction column
  gap 60px
  padding 160px 0
  +below(1350px) {
    padding 160px 20px
  }
  +below(970px) {
    padding 100px 10px
  }
  &__content {
    display grid
    grid-template-columns 405px 885px
    grid-gap 20px
    +below(1350px) {
      overflow-x auto
      padding-bottom 10px
    }
    +below(850px) {
      grid-template-columns 1fr
    }
  }

  &__aside {
    display grid
    grid-gap 30px
    +below(850px) {
      grid-gap 15px
    }
  }

  //&__body {
  //  display grid
  //  grid-template-columns 2fr 1fr
  //  justify-items stretch
  //}

  &__item {
    width 100%
    display flex
    flex-direction column
    gap 20px
    padding 20px 30px
    background-color var(--white)
    color var(--dark)
    border-radius 10px

    &-title {
      margin-bottom 0
      font-weight 600
    }
    &-info {
      display flex
      gap 20px
      color: var(--gray-4-dark);
      flex-wrap wrap
      &--active {
        color var(--gray)
      }
    }
    &-price {
      margin-bottom 0
      margin-top 10px
    }
    &--active {
      background-color var(--pink)
      color var(--white)
    }
  }

  &__body {
    display grid
    grid-template-columns 1.7fr 1.5fr
    justify-items stretch
    padding 20px 30px 20px 30px
    background-color var(--white)
    border-radius 10px
    +below(850px) {
      grid-template-columns 1fr
      grid-gap 50px
    }

    &-left {
      display grid
      align-items stretch
    }

    &-right {
      display flex
      flex-direction column
      //justify-content space-between
      gap 60px
      border-left 1px solid var(--gray-dark)
      padding-left 30px
      +below(850px) {
        border-left none
        padding-left 0
        gap 40px
      }
      //+below(600px) {
      //  gap 40px
      //}
    }

    &-top {
      display flex
      gap 60px
      //+below(600px) {
      //  flex-direction column
      //  gap 30px
      //}
    }

    &-bottom {
      display flex
      justify-content center
      align-items center
    }

    &-text {
      display flex
      flex-direction column
      gap 10px

      &__title {
        margin-bottom 0
        font-size 1.875em
        line-height 38px
      }
      &__subtitle {
        font-size 1.25em
        line-height 25px
        color var(--gray-4-dark)
        word-break break-word
        margin-bottom 20px
        max-width 385px
        //margin 0
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-clamp: 3;
      }
    }

    &-modules {
      display flex
      flex-direction column
      gap 10px
      max-width 385px
      margin-bottom 30px
      word-break break-word

      &__title {
        margin-bottom 5px
        color var(--pink)
      }

      &__subtitle {
        font-weight 500
        line-height 20px
      }
    }

    &-bonuses {
      display flex
      flex-direction column
      gap 10px
      margin-bottom 30px
      max-width 385px
      word-break break-word

      &__title {
        margin-bottom 5px
        color var(--pink)
      }

      &__subtitle {
        font-weight 500
        line-height 20px
      }
    }

    &-guaranty {
      display grid
      grid-gap 15px
      word-break break-word

      &__title {
        margin-bottom 0
        font-weight 600
      }
    }

    &-questions {
      display grid
      grid-gap 15px
      word-break break-word

      &__title {
        margin-bottom 0
        font-weight 600
      }
      &__subtitle {
        max-width 327px

        &--phone {
          color var(--pink)
        }
      }
      &__btn {
        margin-top 15px
        text-transform none
        display grid
        justify-items center
      }
    }

    &-buy {
      background-color var(--main_color_2)
      padding 10px 30px
      border-radius 10px
      width 100%
      max-width 366px
      +below(850px) {
        width 100%
      }
      +below(410px) {
        padding 10px 20px
      }
      +below(315px) {
        padding 10px 15px
      }

      &__text {
        //display flex
        //gap 30px
        //align-items center
        //justify-content center
        display grid
        grid-template-columns 1fr 1fr
        grid-gap 10px 30px

        &-price {
          color var(--pink)
          font-size 1.875em
          line-height 38px
          margin-bottom 0
          +below(410px) {
            font-size 1.625em
          }

          &--old {
            color var(--gray-6-dark)
            text-decoration-line line-through
            font-size 1.375em
            line-height 38px
            margin-bottom 0
            +below(410px) {
              font-size 1.625em
            }
          }
        }
        &-month {
          display flex
          align-items center
          color var(--gray-4-dark)
          font-size 1.25em
          line-height 25px
          margin-bottom 0
          +below(410px) {
            font-size 1em
          }
        }
      }

      &__checkbox {
        padding-top 10px

        & .check{
          gap 6px
        }

        & span {
          font-weight: 400;
          font-size: 0.75em
          gap 2px
          +below(410px){
            font-size: 0.5em
          }
        }

        a {
          color var(--black)
          text-decoration underline
        }
      }

      &__btn {
        width 100%
        margin-top 15px

        &:hover:not(:disabled) {
          background none
        }
      }
    }
  }

  &__promo {
    display flex
    justify-content center
    padding-bottom 10px
  }

  &__promo-content {
    display flex
    justify-content space-around
    align-items center
    max-width 88px
    background var(--white)
    gap 10px
    padding 1px 8px
    border-radius 33px
  }

  &__promo-title {
    font-size 0.875em
    font-weight 700
    line-height 17px
  }

  &__promo-icon {
    cursor pointer

    .icon svg {
      width 12px
      height 12px
    }
  }
}
</style>

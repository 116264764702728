<template>
  <div class="review-slide">
    <div class="review-slide__header">
      <ImgComponent class="review-slide__img" :head_img="item.img" />
      <div class="review-slide__info">
        <span class="review-slide__name">{{ item.name }}</span>
        <span v-if="item.age" class="review-slide__age">{{ item.age | plural("год", "года", "лет") }}</span>
      </div>
    </div>
    <div class="review-slide__content">
      <span>{{ item.description }}</span>
    </div>
  </div>
</template>

<script>
import ImgComponent from "./ImgComponent.vue";
export default {
  name: "ReviewSlide",
  components: { ImgComponent },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="stylus">
.review-slide{
  display flex
  flex-direction column
  gap 40px

  &__img{
    max-height 60px
    max-width 60px
    border-radius: 50%;
  }

  &__header{
    display flex
    gap 20px
    height 60px
    color var(--white)
  }

  &__name{
    font-weight: 600;
    font-size: 1.250em
    line-height: 25px;
  }

  &__age{
    font-weight: 400;
    font-size: 1em
    line-height: 20px;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  &__info{
    display flex
    flex-direction column
    gap 8px
  }

  &__content{
    color var(--white_o8)
  }
}
</style>

<template>
  <section v-if="data && data.length" class="fit container">
    <h2>Кому подойдет</h2>
    <div class="fit__list">
      <div v-for="(item, i) in data" :key="i" class="fit__item">
        <ImgComponent class="fit__item-img" :head_img="item.img" />
        <div class="fit__item-text">
          <h3>{{ item.title }}</h3>
          <span>{{ item.description }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "FitComponent",
  components: { ImgComponent },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="stylus">
.fit {
  display flex
  flex-direction column
  gap 60px
  padding 0 0 80px 0
  +below(1350px) {
    padding 0 20px 80px 20px
  }
  +below(970px) {
    padding 0 10px 80px 10px
  }

  &__list {
    display flex
    gap 20px
    flex-wrap wrap
    +below(1015px) {
      justify-content center
    }
  }
  &__item {
    display flex
    flex-direction column
    max-width 312px
    &-img {
      border-top-left-radius 10px
      border-top-right-radius 10px
      //min-width 312px
      width 100%
      max-width 312px
      min-height 188px
      max-height 188px
    }
    &-text {
      padding 20px 15px
      background-color var(--white)
      border-bottom-left-radius 10px
      border-bottom-right-radius 10px
    }
  }
}
</style>

<template>
  <section class="study-component container">
    <h2>Как проходит обучение</h2>
    <div class="study-component__list">
      <div class="study-component__item">
        <IconComponent name="laptop" />
        <h3 class="study-component__item-title">Этап 1</h3>
        <span class="study-component__item-subtitle">
          Регистрация на платформе <br />
          «Аист Клуб»
        </span>
      </div>
      <div class="study-component__item">
        <IconComponent name="laptop-2" />
        <h3 class="study-component__item-title">Этап 2</h3>
        <span class="study-component__item-subtitle">
          Просмотр видео уроков и прохождение <br />
          практических заданий в любое удобное время
        </span>
      </div>
      <div class="study-component__item">
        <IconComponent name="laptop-3" />
        <h3 class="study-component__item-title">Этап 3</h3>
        <span class="study-component__item-subtitle">
          Обратная связь и поддержка <br />
          от кураторов курса
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "StudyIconComponent",
  components: { IconComponent },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="stylus">
.study-component {
  display flex
  flex-direction column
  gap 60px
  padding 170px 0 80px 0
  +below(1350px) {
    padding 170px 20px 80px 20px
  }
  +below(850px) {
    padding 100px 30px 80px 30px
  }

  &__list {
    display flex
    align-items end
    justify-content space-between
    +below(980px){
      flex-wrap wrap
      padding: 0 70px
      gap 70px 0
    }
    +below(850px){
      justify-content center
      align-items center
      flex-direction column
      flex-wrap nowrap
      padding: 0
    }
  }

  &__item {
    display flex
    flex-direction column
    align-items center
    gap 20px
    text-align center
    & .laptop-icon {
      max-width 284px
      max-height 250px
    }
    & .laptop-2-icon {
      max-width 290px
      max-height 200px
    }
    & .laptop-3-icon {
      max-width 272px
      max-height 200px
    }
    &-title {
      font-size 1.875em
      line-height 38px
    }
  }
}
</style>

<template>
  <section v-if="data || video_title || video_description" class="lesson-video">
    <div class="container-padding">
      <div class="lesson-video__inner">
        <div class="lesson-video__text">
          <h2 v-if="video_title" class="lesson-video__text-title">{{ video_title }}</h2>
          <span v-if="video_description" class="lesson-video__text-subtitle">
            {{ video_description }}
          </span>
        </div>
        <video v-if="data" :src="data.path" controls="controls" ref="video" class="lesson-video__video" />
      </div>
    </div>
  </section>
</template>

<script>
import VideoModal from "components/modals/components/VideoModal.vue";

export default {
  name: "LessonVideoComponent",
  props: {
    video_title: String,
    video_description: String,
    data: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    if (this.$store.state.user === null && this.data) {
      let video = this.$refs.video;
      let modals = this.$store.state._modals;

      video.addEventListener("timeupdate", function () {
        if (video.currentTime >= 60) {
          video.pause();
          if (video.paused && !modals.length) {
            modals.push({
              component: VideoModal,
            });
          }
        }
      });
    }
  },
};
</script>

<style lang="stylus">
.lesson-video {
  background-color var(--white)
  padding 60px 0

  &__inner {
    display flex
    flex-direction column
    align-items center
    gap 60px
  }

  &__text {
    display flex
    flex-direction column
    align-items center
    gap 20px
    &-title {
      color var(--pink)
    }
    &-subtitle {
      font-size 1.25em
      line-height 26px
      text-align center
    }
  }

  &__video {
    border-radius 5px
    width 100%
    max-width 1100px
    height 540px
    +below(700px) {
      height 430px
    }
    +below(450px) {
      height 280px
    }
    +below(450px) {
      height 250px
    }
  }
}
</style>

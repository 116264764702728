<template>
  <section class="about">
    <div class="about__left" />
    <div class="about__right" :class="{ 'about__certificates-empty': !haveCertificates }">
      <div class="about__text">
        <h2 class="about__text-title">{{ data?.title_purpose }}</h2>
        <span class="about__text-subtitle">
          {{ data?.description_purpose }}
        </span>
      </div>
      <div class="about__info">
        <div class="about__info-item">
          <h3 class="about__info-item__number">{{ data?.count_mother }}</h3>
          <div class="about__info-item__text">
            <span class="about__info-item__title">мам</span>
            <span class="about__info-item__subtitle">прошли обучение</span>
          </div>
        </div>
        <div class="about__info-item">
          <h3 class="about__info-item__number">{{ data?.count_child }}</h3>
          <div class="about__info-item__text">
            <span class="about__info-item__title">аистят</span>
            <span class="about__info-item__subtitle">появились на свет</span>
          </div>
        </div>
      </div>
      <div class="about__back">
        <IconComponent name="bird" />
        <span class="about__back-title">Со 2 и 3 малышом возвращаются к нам</span>
      </div>
      <button
        v-if="haveCertificates"
        @click.prevent="openCertificateModal"
        class="about__btn btn btn--main btn--lg"
      >
        ПОСМОТРЕТЬ СЕРТИФИКАТЫ
      </button>
    </div>
  </section>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import CertificateModal from "components/modals/components/CertificateModal.vue";
export default {
  name: "AboutComponent",
  props: {
    data: {
      type: Object,
      default: () => [],
    },
  },
  components: { IconComponent },
  computed: {
    certificates() {
      return this.$store.state.certificates;
    },
    haveCertificates() {
      return this.certificates.length;
    },
  },
  methods: {
    openCertificateModal() {
      this.$store.state._modals.push({
        component: CertificateModal,
        options: {
          data: this.certificates,
        },
      });
    },
  },
};
</script>

<style lang="stylus">
.about {
  display flex
  color var(--white)

  &__certificates-empty{
    padding-bottom: 162px !important;

    +below(970px) {
      padding 30px 20px !important
    }
  }

  &__left {
    background-image url("/static/images/about.png")
    background-repeat no-repeat
    width 100%
    background-size: cover;
    background-position-x: 80%;
    +below(970px) {
      display none
    }
  }

  &__right {
    background-color var(--dark)
    padding 60px 80px
    width 100%
    display flex
    flex-direction column
    +below(1200px){
      padding: 60px 40px
    }
    +below(970px) {
      align-items center
      padding 30px 20px
    }
    +below(500px) {
      padding 30px 10px
    }
  }

  &__text {
    display flex
    flex-direction column
    gap 20px
    +below(465px) {
      gap 10px
    }
    &-title {
      max-width 572px
      font-size 2.5em
      line-height 54px
      color var(--pink)
      +below(1200px){
        font-size: 2em
      }
    }
    &-subtitle {
      max-width 688px
      font-size 1.25em
      line-height 26px
      //text-align center
      color var(--gray-dark)
    }
  }

  &__info {
    display flex
    gap 30px
    margin-top 60px
    +below(970px) {
      margin-top 30px
    }
    +below(465px) {
      width 100%
      flex-direction column
      margin-top 30px
    }
    &-item {
      display flex
      gap 10px
      &__number {
        font-size 3.75em
        line-height 60px
        margin-bottom 0
        +below(1200px){
          font-size: 3em
        }
      }
      &__title {
        font-size 1.25em
        line-height 30px
        font-weight 700
      }
      &__subtitle {
        color var(--gray)
      }
      &__text {
        display flex
        flex-direction column
      }
    }
  }

  &__back {
    display flex
    align-items center
    gap 10px
    margin-top 30px
    +below(465px) {
      width 100%
      justify-content flex-start
    }

    & .icon {
      max-width 30px
      max-height 30px
      +below(465px) {
        max-width 20px
        max-height 20px
      }
    }

    &-title {
      font-size 1.25em
      line-height 26px
      +below(970px) {
        font-size 1.125em
      }
      +below(465px) {
        font-size 1em
      }
    }
  }

  &__btn {
    margin-top 82px
    max-width 407px
    +below(970px) {
      margin-top 42px
    }
    +below(465px) {
      font-size 1em
      width 100%
      max-width 100%
      padding 0 10px
    }
  }
}
</style>

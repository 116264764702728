<template>
  <div class="m download-modal">
    <button class="download-modal__close" @click="closeModal">
      <IconComponent name="close-square" />
    </button>
    <div class="m__content download-modal__content">
      <div class="m__header">
        <span class="m__subtitle" v-if="!done">Заполните форму, чтобы получить доступ к программе</span>
        <span class="m__title" v-if="done">Готово!</span>
        <span class="m__subtitle" v-if="done">Программа курса отправлена вам на почту</span>
      </div>
      <form class="m__body" @submit.prevent="submit" v-if="!done">
        <div class="m__field">
          <InputComponent v-model="form.email.value" :errors="form.email.errors" placeholder="Почта" />
          <InputComponent
            v-model="form.phone.value"
            :errors="form.phone.errors"
            placeholder="+7 (_ _ _) _ _ _-_ _-_ _"
            mask="+7 (###)-###-##-##"
          />
        </div>
        <button class="btn btn--main btn--md consultation-modal__btn" :disabled="!form.checkbox.value">
          <span v-if="!loading">Скачать программу</span>
          <LoadingIndicator title="Отправляем" v-show="loading" />
        </button>
        <div class="download-modal__checkbox">
          <CheckboxComponent v-model="form.checkbox.value">
            <span>
              Соглашаюсь с
              <router-link :to="{ name: 'privacy-policy' }"> политикой конфиденциальности </router-link>
            </span>
          </CheckboxComponent>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";
import POST from "@/graphql/mutations/DownloadProgram.graphql";
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";
export default {
  name: "DownloadModal",
  components: { CheckboxComponent, LoadingIndicator, InputComponent, IconComponent },
  props: {
    course_id: {
      type: Number,
    },
  },
  data() {
    return {
      done: false,
      loading: false,
      form: {
        name: {
          value: null,
          required: true,
          errors: [],
        },
        email: {
          value: null,
          required: true,
          errors: [],
        },
        phone: {
          value: "",
          required: true,
          errors: [],
        },
        checkbox: {
          value: true,
          required: true,
          errors: [],
        },
      },
    };
  },
  watch: {
    "form.phone.value"(newValue, oldValue) {
      if (newValue.charAt(0) === "8" && oldValue.charAt(0) !== "8") {
        this.form.phone.value = "+7" + newValue.slice(1);
      }
    },
  },
  methods: {
    closeModal() {
      this.$store.state._modals = [];
    },
    resetErrors() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].errors = [];
      });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].errors.push(err.extensions.validation[key][0]);
            }
          });
        }
      });
    },
    submit() {
      this.loading = true;
      this.resetErrors();
      this.$apollo
        .mutate({
          mutation: POST,
          variables: {
            id: this.course_id,
            phone: this.form.phone.value,
            email: this.form.email.value,
          },
        })
        .then(() => {
          this.loading = false;
          this.$notify({
            title: "Готово",
            duration: 5000,
            speed: 200,
            type: "success",
          });
          this.done = true;
        })
        .catch(({ graphQLErrors }) => {
          this.$notify({
            title: "Ошибка",
            duration: 5000,
            speed: 200,
            type: "error",
          });
          this.parseGqlErrors(graphQLErrors);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="stylus">
.download-modal {
  display flex
  flex-direction column

  &__content {
    display flex
    flex-direction column
    gap 30px
  }

  &__close {
    width 40px
    height 40px
    padding 13px
    z-index 1
    background none
    border none
    transition var(--transition)
    cursor pointer

    .icon {
      width 16px
      height 16px

      svg path {
        fill var(--white)
        transition var(--transition)
      }
    }

    &:hover {
      .icon svg path {
        fill var(--pink)
      }
    }
  }

  & span {
    text-align center
    font-weight 600
    //margin-bottom 20px
  }
  &__btn {
    width 100%
    max-width 207px

    .btn {
      max-width 207px
    }
  }
  &__checkbox {
    width 100%

    & .check{
      gap 6px
    }

    & span{
      font-weight: 400;
      font-size: 1em
      gap 2px

      +below(800px){
        font-size: 0.8em
      }
      +below(400px){
        font-size: 0.6em
      }
    }

    a {
      color var(--black)
      text-decoration underline
    }
  }
}
</style>

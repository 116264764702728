<template>
  <section class="home-review" v-if="data && data.length">
    <div class="home-review__header">
      <div class="container">
        <h2>{{ title }}</h2>
      </div>
    </div>
    <div class="wrapper">
      <SliderComponent
        class="home-review__slider"
        buttons
        paginate
        :items="data"
        :slider-options="SliderOptions"
        v-slot="{ item }"
      >
        <ReviewSlide :item="item" />
      </SliderComponent>
    </div>
  </section>
</template>

<script>
import SliderComponent from "components/SliderComponent.vue";
import ReviewSlide from "components/ReviewSlide.vue";
export default {
  name: "ReviewsComponent",
  components: { ReviewSlide, SliderComponent },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      SliderOptions: {
        slidesPerView: 1,
        spaceBetween: 10,
        // autoHeight: true,
        loop: true,
        breakpoints: {
          628: {
            slidesPerView: 1.2,
          },
          700: {
            slidesPerView: 1.3,
          },
          1200: {
            slidesPerView: 2.47,
          },
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // centeredSlides: true,
      },
    };
  },
};
</script>

<style lang="stylus">
.home-review{
  padding-top: 160px
  padding-bottom: 60px
  +below(1330px) {
    padding-left: 20px
  }
  +below(700px) {
    padding-top 60px
    padding-bottom 20px
    padding-left: 0
  }

  &__header{
    padding-right: 30px
    +below(700px) {
      padding-left 20px
    }
  }

  & .wrapper{
    margin: 0 auto
    margin-right: 0;
    width 100%
    max-width: calc(100vw - (100vw - 1310px) / 2);
  }

  &__slider{
    width 100%

    & .container{
      position: absolute;
      bottom: -8px;
    }

    & .swiper{
      padding 40px 0 120px
      +below(700px) {
        padding-left: 0
      }

      &-slide{
        width 100%
        height auto
        background var(--dark_o6)
        padding 60px 40px
        border-radius 10px

        +below(628px){
          padding: 30px 15px
        }
      }
      &-slide-active{
        background var(--dark)
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      }
      &-slide-next{
        background var(--dark_o8)
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      }
    }
    & .swiper-pagination{
      display flex
      align-items center
      +below(700px) {
        padding 0 15px
      }
    }
    & .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
      margin:0
    }
    & .swiper-pagination-bullet{
      width: 96px;
      height: 4px;
      border-radius 0
      &-active{
        background var(--pink)
        height 6px
        border-radius 3px
      }
    }
  }

  & .swiper-button{
    width: 170px;
    height 58px
    display flex
    gap 10px
    right: 0;
    position: absolute;
    bottom: 10px;
    transition var(--transition)
    +below(1330px) {
      margin-right 20px
    }
    +below(700px) {
      display none
    }

    &-next{
      padding: 20px 30px
      border 2px solid var(--pink)
      position static
      height 100%
      width 100%
      margin:0
      border-radius var(--small_radius)
      transition var(--transition)

      &:hover {
        background var(--pink)

        & .icon svg path {
          fill var(--white)
        }
      }

      & .icon svg path{
        fill var(--dark)
      }

      &::after{
        content none
      }
    }
    &-prev{
      padding: 20px 30px
      border 2px solid var(--pink)
      position static
      height 100%
      width 100%
      margin:0
      border-radius var(--small_radius)
      transition var(--transition)

      &:hover {
        background var(--pink)

        & .icon svg path {
          fill var(--white)
        }
      }

      .icon{
        transform rotate(180deg)
      }

      & .icon svg path{
        fill var(--dark)
      }

      &::after{
        content none
      }
    }
  }

  //&__slide{
  //  height 714px
  //  width 100%
  //  background var(--black)
  //  padding 60px 40px
  //}
}
</style>

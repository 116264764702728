<template>
  <section class="catalog-banner">
    <ImgComponent class="catalog-banner__img" v-if="data?.img" :head_img="data.img" />
    <div class="catalog-banner__gradient" />
    <div class="catalog-banner__inner">
      <div class="catalog-banner__content container">
        <BreadcrumbsComponent :links="links" />
        <div class="catalog-banner__text">
          <h2 class="catalog-banner__text-title" v-if="data?.heading">{{ data.heading }}</h2>
          <h1 class="catalog-banner__text-subtitle" v-if="data?.title">{{ data.title }}</h1>
          <span class="catalog-banner__text-description" v-if="data?.subtitle">{{ data.subtitle }}</span>
        </div>
        <div class="catalog-banner__buttons">
          <router-link :to="{ hash: '#tariff' }" class="btn btn--main btn--lg">Купить курс</router-link>
          <button
            v-if="data.file"
            class="btn btn--outline btn--lg btn--lg--white"
            @click.prevent="openDownloadModal(data.id)"
          >
            Скачать программу
          </button>
        </div>
      </div>
    </div>
    <ConsultationButton />
  </section>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import DownloadModal from "components/modals/components/DownloadModal.vue";
import ConsultationButton from "components/СonsultationButton.vue";
export default {
  name: "CatalogBannerComponent",
  components: { ConsultationButton, ImgComponent, BreadcrumbsComponent },
  props: {
    data: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      links: [
        {
          children: {
            title: "Каталог курсов",
          },
        },
      ],
    };
  },
  methods: {
    openDownloadModal(course_id) {
      this.$store.state._modals.push({
        options: {
          course_id,
        },
        component: DownloadModal,
      });
    },
  },
};
</script>

<style lang="stylus">
.catalog-banner {
  width 100%
  position relative
  padding-bottom 80px
  +below(860px) {
    padding-bottom 30px
  }

  &__img {
    position absolute
    height 100%
    z-index 0
  }
  &__gradient{
    position absolute
    height 100%
    background-color transparent
    background: linear-gradient(90deg, #FFFFFF 40.68%, rgba(255, 255, 255, 0) 60.13%);
    opacity 0.4
    width 100%
    z-index 1
  }

  &__inner {
    //background: linear-gradient(90deg, #FFFFFF 40.68%, rgba(255, 255, 255, 0) 60.13%);
    position relative
    z-index: 2;
  }

  &__content {
    padding 30px 0 0 0
    display flex
    flex-direction column
    gap 60px
    +below(1350px) {
      padding 30px 20px 0 20px
    }
    +below(700px) {
      gap 30px
    }
  }

  &__text {
    display flex
    flex-direction column
    gap 10px
    max-width 836px
    word-break break-word
    &-title {
      color var(--pink)
      font-size 2.5em
      line-height 48px
    }
    &-description {
      color var(--gray-4-dark)
      font-size 1.25em
      line-height 26px
    }
  }

  &__buttons {
    display flex
    gap 23px
    margin-top 20px
    +below(700px) {
      flex-direction column
    }

    & .btn--lg {
      +below(700px) {
        height 65px
      }
      +below(375px) {
        padding 0 35px
        font-size 1em
      }

      &--white {
        background var(--white)
      }
    }
  }
}
</style>

<template>
  <section v-if="teachers && teachers.length" class="teachers">
    <h2 class="teachers__header">Преподаватели</h2>
    <div class="teachers__list">
      <div v-for="(teacher, i) in teachers" :key="i" class="teachers__item">
        <ImgComponent class="teachers__item-img" :head_img="teacher.img" />
        <div class="teachers__item-text">
          <h3>{{ teacher.title }}</h3>
          <span>{{ teacher.post }}</span>
        </div>
      </div>
    </div>
    <button
      v-if="teachers.length !== total && total > 5"
      @click="showTeachers"
      class="teachers__btn btn btn--main btn--lg"
    >
      <span>Показать еще</span>
    </button>
    <button
      v-if="teachers.length === total && total > 5"
      @click="hideTeachers"
      class="teachers__btn btn btn--main btn--lg"
    >
      <span>Скрыть</span>
    </button>
  </section>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "CatalogTeachersComponent",
  components: { ImgComponent },
  props: {
    teachers: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    async showTeachers() {
      this.$emit("show");
    },
    async hideTeachers() {
      this.$emit("hide");
    },
  },
};
</script>

<style lang="stylus">
.teachers {
  display flex
  flex-direction column
  align-items center
  gap 60px
  background-color var(--gray-4-dark)
  padding 60px 105px 60px 105px
  +below(700px) {
    padding 60px 10px 60px 10px
  }

  &__header {
    color var(--white)
  }

  &__list {
    display flex
    gap 30px
    flex-wrap wrap
    justify-content center
  }

  &__item {
    display flex
    flex-direction column
    align-items center
    color var(--white)
    gap 20px
    text-align center
    max-width 260px

    &-img {
      width 100%
      min-width 260px
      max-width 260px
      height 260px
      max-height 260px
      border-radius 100%
    }

    h3 {
      margin-bottom 10px
    }

    span {
      color var(--gray-dark)
    }
  }

  &__btn {
    margin-top 20px
  }
}
</style>

<template>
  <section v-if="data && data.length" class="faq">
    <div class="faq__content container">
      <h2 class="faq__header">FAQ</h2>
      <ul class="faq__list">
        <li
          v-for="(item, i) in data"
          :key="i"
          class="faq__item"
          :class="{ 'faq__item--active': activeFaq === i }"
          @click="toggleFaq(i)"
        >
          <span class="faq__number">
            <span>0{{ i + 1 }}</span>
          </span>
          <div class="faq__text">
            <span class="faq__title" :class="{ 'faq__title--active': activeFaq === i }">
              <span>
                {{ item.question }}
              </span>
              <IconComponent name="DownArrow" />
            </span>
            <div v-if="activeFaq === i" class="faq__description">
              <span>
                {{ item.answer }}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeFaq: null,
    };
  },
  methods: {
    toggleFaq(i) {
      if (this.activeFaq === i) {
        this.activeFaq = null;
        return;
      }
      this.activeFaq = i;
    },
  },
  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
.faq {
  width 100%
  padding: 0 30px;
  +below(700px) {
    padding 0 15px
  }

  &__content {
    padding-top: 60px
    padding-bottom 120px
    margin 0 auto
    +below(700px) {
      padding-top: 20px
      padding-bottom 40px
    }
  }

  &__header {
    margin-bottom 54px
    font-weight 700
    +below(1060px) {
      font-size 1.875em
      margin-bottom 50px
    }
    +below(700px) {
      //line-height 40px
      margin-bottom 40px
    }
  }

  &__list {

  }

  &__text {
    width 100%
    +below(700px) {
      display flex
    flex-direction column
    gap 15px
    }
  }

  &__title {
    display flex
    justify-content space-between
    align-items center
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color var(--dark-2)
    width 100%
    padding: 35px 0;

    .icon {
      transition var(--transition)
      transform rotate(270deg)

      & svg path{
        fill var(--dark-2_o1)
      }
    }

    &--active {
      +below(700px) {
        margin-top 17px
      }
      .icon {
        transform rotate(360deg)
        width 18px
        height 20px

        & svg path{
          fill var(--dark-2)
        }
      }
    }

    +below(1060px) {
      font-size 1.125em
    }
    +below(700px) {
      font-size 1em
    line-height 20px
    padding 0
    }
  }

  &__item {
    cursor: pointer;
    padding 0 30px
    border-top 1px solid var(--gray-dark)
    border-bottom 1px solid var(--gray-dark)
    display flex
    gap 100px
    align-items center
    +below(700px) {
      gap 10px
      padding 15px 0
    }
    //&:last-child {
    //  border-bottom 1px solid var(--gray)
    //}
  }

  &__number{
    padding-top: 10px
    font-weight: 700;
    font-size: 60px;
    line-height: 75px;
    color var(--gray-dark)
    align-self flex-start
    +below(700px) {
      font-size 2.625em
    line-height 53px
    padding-top 0
    width 62px
    }
  }

  &__description {
    font-weight: 400;
    font-size: 1.250em
    line-height: 130%;
    color var(--dark-2_o8)
    padding 0 0 30px 0

    +below(628px){
      font-size: 1em;
    }

    span {
      white-space pre-line
    }
  }
}
</style>

<template>
  <section v-if="data.problem_title && data.problem_description" class="problem-banner container">
    <h2 class="problem-banner__title">Ориентированы на решение ваших проблем</h2>
    <div class="problem-banner__inner">
      <div class="problem-banner__text">
        <h2 class="problem-banner__text-title" v-if="data?.problem_title">{{ data.problem_title }}</h2>
        <span class="problem-banner__text-subtitle" v-if="data?.problem_title">
          {{ data.problem_description }}
        </span>
      </div>
      <a
        class="btn btn--main btn--lg"
        @click.prevent="openProblemModal(data.problem_title, data.problem_description)"
      >
        <span>Как поможет курс</span>
      </a>
    </div>
  </section>
</template>

<script>
import ProblemModal from "components/modals/components/ProblemModal.vue";

export default {
  name: "ProblemBannerComponent",
  props: {
    data: {
      type: Object,
      default: () => [],
    },
  },
  methods: {
    openProblemModal(title, description) {
      this.$store.state._modals.push({
        component: ProblemModal,
        options: {
          data: {
            title,
            description,
          },
        },
      });
    },
  },
};
</script>

<style lang="stylus">
.problem-banner {
  display flex
  flex-direction column
  gap 60px
  padding 160px 0 0 0
  +below(1350px) {
    padding 100px 20px 0 20px
  }
  +below(970px) {
    padding 100px 10px 0 10px
  }

  &__inner {
    display flex
    flex-direction column
    align-items center
    gap 60px
    background-image url("/static/images/problem-bg.png")
    background-repeat no-repeat
    border-radius 10px
    padding 60px 0
    +below(500px) {
      gap 30px
      padding 30px 0
    }
  }

  &__title  {
    max-width 891px
  }

  &__text {
    display flex
    flex-direction column
    align-items center
    gap 30px
    padding 0 30px
    &-title {
      font-size 2.5em
      color var(--pink)
      line-height 120%
      +below(500px) {
        font-size 1.625em
      }
    }
    &-subtitle {
      font-size 1.25em
      color var(--white)
      line-height 130%
      text-align center
      +below(500px) {
        font-size 1em
      }
    }
  }

  & .btn {
    +below(1350px) {
      padding 0 30px
    }
    +below(330px) {
      padding 0 10px
    }
  }
}
</style>

<template>
  <div class="video-modal">
    <button class="video-modal__close" @click="closeModal">
      <IconComponent name="close-square" />
    </button>
    <section class="video-modal__content">
      <h3 class="video-modal__title">Зарегистрируйтесь</h3>
      <span class="video-modal__subtitle">Чтобы продолжить просмотр, необходимо зарегистрироваться</span>
      <div class="video-modal__btn">
        <a class="btn btn--main btn--md" @click.prevent="openRegisterModal">
          <span>Зарегистрироваться</span>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import RegistrationForm from "components/modals/components/RegistrationForm.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "VideoModal",
  props: {
    data: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    closeModal() {
      this.$store.state._modals = [];
    },
    openRegisterModal() {
      this.$store.state._modals = [];
      this.$store.state._modals.push({
        component: RegistrationForm,
      });
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.video-modal {
  display flex
  flex-direction column
  align-items flex-end

  &__content {
    padding 60px 30px
    background-color var(--white)
    display flex
    flex-direction column
    gap 30px
    text-align center
    border-radius 10px
    +below(400px) {
      padding 30px 15px
      gap 20px
    }
  }

  &__close {
    width 40px
    height 40px
    padding 13px
    z-index 1
    background none
    border none
    transition var(--transition)
    cursor pointer

    .icon {
      width 16px
      height 16px

      svg path {
        fill var(--white)
        transition var(--transition)
      }
    }

    &:hover {
      .icon svg path {
        fill var(--pink)
      }
    }
  }

  &__title {
    font-size 1.875em
    line-height 38px
    margin-bottom 0
    +below(400px) {
      font-size 1.5em
    }
  }
  &__subtitle {
    font-size 1.25em
    line-height 25px
    +below(400px) {
      font-size 0.875em
    }
  }
  &__btn {
    margin-top 10px
    width 100%
    justify-content center
    +below(400px) {
      margin-top 0
    }

    & .btn {
      max-width 213px
    }
  }
}
</style>

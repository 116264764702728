<template>
  <div class="certificate">
    <button class="certificate__close" @click="closeModal">
      <IconComponent name="close-square" />
    </button>
    <section class="certificate__content">
      <div class="certificate__slides">
        <div class="swiper" ref="items">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, i) in data" :key="i">
              <ImgComponent :head_img="item.img" class="certificate__slide-item" />
            </div>
          </div>
          <div class="swiper__pagination">
            <div class="certificate__pagination"></div>
            <div class="certificate__buttons">
              <div class="certificate__buttons-prev">
                <IconComponent name="arrow-right" />
              </div>
              <div class="certificate__buttons-next">
                <IconComponent name="arrow-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "CertificateModal",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  components: { IconComponent, ImgComponent },
  data() {
    return {
      loading: false,
      swiper: null,
    };
  },
  methods: {
    closeModal() {
      this.$store.state._modals = [];
    },
  },
  mounted() {
    require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, {
        slidesPerView: 5,
        spaceBetween: 10,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: ".certificate__pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".certificate__buttons-next",
          prevEl: ".certificate__buttons-prev",
        },
        breakpoints: {
          350: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          380: {
            slidesPerView: 1.2,
            spaceBetween: 0,
          },
          420: {
            slidesPerView: 1.4,
            spaceBetween: 0,
          },
          450: {
            slidesPerView: 1.5,
            spaceBetween: 0,
          },
          490: {
            slidesPerView: 1.6,
            spaceBetween: 0,
          },
          560: {
            slidesPerView: 1.8,
            spaceBetween: 0,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 2.5,
            spaceBetween: 0,
          },
          960: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          1240: {
            slidesPerView: 4,
            spaceBetween: 0,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 0,
          },
        },
      });
    });
  },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css"
.certificate {
  display flex
  flex-direction column
  align-items flex-end
  width 100%
  max-width 1200px
  height 530px
  max-height 530px

  &__content {
    width 100%
    display flex
    align-items center
    border-radius 10px
    background-color var(--white)
    padding 0 30px
    +below(1350px) {
      margin 0 20px
    }
    +below(970px) {
      margin 0 10px
    }
    +below(768px) {
      gap 20px
      margin-bottom 40px
    }
  }

  &__close {
    width 40px
    height 40px
    padding 13px
    z-index 1
    background none
    border none
    transition var(--transition)
    cursor pointer

    .icon {
      width 16px
      height 16px

      svg path {
        fill var(--white)
        transition var(--transition)
      }
    }

    &:hover {
      .icon svg path {
        fill var(--pink)
      }
    }
  }

  &__background {
    absolute left bottom
    transform translateY(100%)
  }

  &__title {
    font-weight: 800;
    font-size: 2.125em;
    text-align: center;
    color: var(--main);
    margin 0
    +below(768px) {
      font-size 1.8em
    }
  }

  .stats {
    padding 0 100px
    max-width 1000px
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 30px
    margin-top 50px
    +below(1200px) {
      padding 40px
      grid-template-columns repeat(2, 1fr)
    }
    +below(768px) {
      padding 20px
      grid-template-columns 1fr
    }
    +below(560px) {
      padding 16px
    }
  }

  &__slides {
    position relative
    width 100%

    .swiper-wrapper {
      margin-bottom 73px
    }

    .swiper-slide {
      width auto
      display flex
      flex-direction column
      justify-content center
      align-items center
      height 100%
      transition var(--transition)
      padding 30px 0
      border-radius: 10px;
      &.swiper-slide-active {
        transform scale(1.22)
        filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1));
        z-index 1
      }

      img {
        min-width 241px
        max-width 241px
        min-height 300px
        max-height 300px
        object-fit fill
        transition var(--transition)
      }

      .certificate__slide-item {
        border-radius 10px
        +below(310px) {
          padding 0 8px
        }
      }
    }
  }

  &__pagination {
      display flex
      align-items center
      +below(800px) {
        padding 0 15px
        justify-content center
      }
  }

  & .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
    margin:0
  }

  & .swiper-pagination-bullet {
    width: 96px;
    height: 4px;
    border-radius 0
    &-active{
      background var(--pink)
      height 6px
      border-radius 3px
    }
  }

  &__buttons {
    width: 170px;
    height 58px
    display flex
    gap 10px
    right: 0;
    position: absolute;
    bottom: 10px;
    +below(1330px) {
      margin-right 20px
    }
    +below(800px) {
      display none
    }

    &-next {
      padding: 20px 30px
      background var(--pink)
      position static
      height 100%
      width 100%
      margin:0
      border-radius var(--small_radius)

      &::after {
        content none
      }
    }
    &-prev {
      padding: 20px 30px
      border 2px solid var(--pink)
      position static
      height 100%
      width 100%
      margin:0
      border-radius var(--small_radius)

      .icon{
        transform rotate(180deg)
      }

      .icon svg path {
        fill var(--dark)
      }

      &::after {
        content none
      }
    }
  }
}
</style>

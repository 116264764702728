<template>
  <div class="catalog-promo container" v-if="Math.sign(timer.total) === 1">
    <div class="catalog-promo__content">
      <div class="catalog-promo__left">
        <ImgComponent class="catalog-promo__img" :head_img="data.img" />
        <div class="catalog-promo__wrapper">
          <div class="catalog-promo__info">
            <span class="catalog-promo__action">АКЦИЯ</span>
            <span>до {{ endDate }}</span>
          </div>
          <span class="catalog-promo__title">{{ data.description }}</span>
          <form class="catalog-promo__form" @submit.prevent="submit">
            <InputComponent
              v-model="form.promocode.value"
              :errors="form.promocode.errors"
              placeholder="Промокод"
            />
            <button :disabled="loading" class="btn btn--main btn--md">
              <span>Применить</span>
            </button>
          </form>
          <div v-if="$store.state?.search_promocode?.value" class="catalog-promo__actual">
            <span class="catalog-promo__actual-title">{{ $store.state.search_promocode.value }}</span>
            <div @click="togglePromo" class="catalog-promo__actual-icon">
              <IconComponent name="promo-close" />
            </div>
          </div>
        </div>
      </div>
      <div class="catalog-promo__right">
        <span class="catalog-promo__right-title">До конца предложения осталось:</span>
        <div class="catalog-promo__timer">
          <div class="catalog-promo__timer-item">
            <span class="catalog-promo__number">{{ timer.day }}</span>
            <span class="catalog-promo__text">дней</span>
          </div>
          <div class="catalog-promo__dotts">
            <IconComponent name="dotts" />
          </div>
          <div class="catalog-promo__timer-item">
            <span class="catalog-promo__number">{{ timer.hours }}</span>
            <span class="catalog-promo__text">часов</span>
          </div>
          <div class="catalog-promo__dotts">
            <IconComponent name="dotts" />
          </div>
          <div class="catalog-promo__timer-item">
            <span class="catalog-promo__number">{{ timer.minutes }}</span>
            <span class="catalog-promo__text">минут</span>
          </div>
          <div class="catalog-promo__dotts">
            <IconComponent name="dotts" />
          </div>
          <div class="catalog-promo__timer-item">
            <span class="catalog-promo__number">{{ timer.second }}</span>
            <span class="catalog-promo__text">секунд</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import GET from "@/graphql/mutations/SearchPromocode.graphql";
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "CatalogPromoComponent",
  components: { ImgComponent, InputComponent, IconComponent },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    tariffs: Array,
  },
  mounted() {
    this.timer.total = moment().diff(this.data.end_date) * -1;
    this.intervalId = setInterval(this.startTimer, 1000);
    this.show = true;
    console.log(this.data);
  },
  data() {
    return {
      form: {
        promocode: {
          value: null,
          errors: [],
        },
      },
      // entered: false,
      intervalId: null,
      loading: false,
      timer: {
        total: null,
        day: null,
        hours: null,
        minutes: null,
        second: null,
      },
    };
  },
  methods: {
    togglePromo() {
      if (this.exists) {
        this.tariffs.forEach((item) => {
          if (this.$store.state.search_promocode.tariffs.includes(item.id)) {
            item.price = item.oldPrice;
            item.oldPrice = null;
            item.promocode = null;
          }
        });
        this.$store.state.search_promocode.value = null;
        this.form.promocode.value = "";
        this.form.promocode.errors = [];
        this.$store.state.search_promocode.exists = false;
      }
    },
    submit() {
      this.loading = true;
      if (!this.exists) {
        if (this.form.promocode.value) {
          this.$apollo
            .mutate({
              mutation: GET,
              variables: {
                course_id: parseInt(this.$route.params.id),
                promocode: this.form.promocode.value,
              },
            })
            .then(({ data }) => {
              this.loading = false;
              if (data.search_promocode.exists) {
                this.$store.state.search_promocode = data.search_promocode;
                this.$store.state.search_promocode.value = this.form.promocode.value;
                this.tariffs.forEach((item) => {
                  if (data.search_promocode.tariffs.includes(item.id)) {
                    item.oldPrice = item.price;
                    item.promocode = this.form.promocode.value;
                    item.price =
                      parseInt(item.price) -
                      (parseInt(item.price) * parseInt(data.search_promocode.percent)) / 100;
                  }
                });
                this.form.promocode.value = "";
                this.form.promocode.errors = [];
                this.$notify({
                  title: "Готово",
                  duration: 5000,
                  speed: 200,
                  type: "success",
                });
              } else this.form.promocode.errors = ["Такого промокода не существует"];
            })
            .catch(() => {
              this.$notify({
                title: "Ошибка",
                duration: 5000,
                speed: 200,
                type: "error",
              });
              this.loading = false;
            });
        } else {
          this.loading = false;
          this.form.promocode.errors = ["Введите промокод"];
        }
      } else {
        this.loading = false;
        this.form.promocode.errors = ["Промокод уже введен"];
      }
    },
    startTimer() {
      if (this.timer.total > 0) {
        this.timer.day = Math.floor((this.timer.total / (1000 * 60 * 60 * 24)) % 30);
        this.timer.hours = Math.floor((this.timer.total / (1000 * 60 * 60)) % 24);
        this.timer.minutes = Math.floor((this.timer.total / (1000 * 60)) % 60);
        this.timer.second = Math.floor((this.timer.total / 1000) % 60);
        this.timer.total -= 1000;
      } else clearInterval(this.intervalId);
    },
  },
  computed: {
    exists() {
      return this.$store.state.search_promocode ? this.$store.state.search_promocode.exists : false;
    },
    endDate() {
      return moment(this.data.end_date).format("DD.MM");
    },
  },
};
</script>

<style lang="stylus">
.catalog-promo{
  margin-top 88px
  +below(1350px) {
    padding 0 20px
  }
  +below(970px) {
    padding 0 10px
  }

  &__content {
    background-color var(--gray-5-dark)
    //background-image url("/static/images/action.png")
    background-size cover
    background-repeat no-repeat
    border-radius 10px
    display flex
    justify-content space-between
    +below(1235px) {
      flex-direction column
      align-items center
    }
  }

  &__img{
    position absolute
    height 100%
    width 100%
    object-fit cover
    z-index 1
    border-radius 10px 0 0 10px
  }

  &__error{
    border 1px solid var(--red)
  }

  &__wrapper {
    padding: 60px
    display flex
    flex-direction column
    gap 30px
    width 100%
    max-width 500px
    box-sizing content-box
    position: relative
    z-index 2
    +below(650px) {
      padding 30px 0
    }
    +below(535px) {
      align-items center
    }
    +below(425px) {
      gap 20px
    }
    +below(352px) {
      gap 15px
      padding 20px 0
    }
  }

  &__left {
    width 100%
    position: relative;
  }

  &__action{
    border: 2px solid var(--pink)
    border-radius 5px
    padding 6px 14px
    color var(--white)
  }

  &__info{
    font-weight: 700;
    font-size 1.25em
    line-height: 130%;
    display flex
    color var(--white)
    gap 20px
    align-items center
    +below(450px) {
      font-size 1em
    }
    +below(425px) {
      font-size 0.875em
    }
    +below(352px) {
      font-size 0.75em
    }
  }

  &__title{
    font-weight: 600;
    font-size: 1.875em;
    line-height: 130%
    color var(--white)
    +below(535px) {
      padding 0 7px
      text-align center
    }
    +below(510px) {
      font-size 1.625em
    }
    +below(450px) {
      font-size 1.5em
    }
    +below(425px) {
      font-size 1.25em
    }
    +below(352px) {
      font-size 1em
    }
  }

  &__form{
    display flex
    //align-items center
    gap 20px

    .btn {
      height 52px !important
    }
    +below(535px) {
      gap 20px
    }
    +below(535px) {
      flex-direction column
      align-items center
      gap 15px
      width 100%

      & .btn {
        width 100%
        max-width 327px
      }
    }
    +below(365px) {
      padding 0 7px
      gap 10px
    }

    & .input {
      width 100%
      max-width 327px
    }
  }

  &__actual {
    display flex
    justify-content space-around
    align-items center
    max-width 88px
    background var(--white)
    gap 10px
    padding 1px 8px
    border-radius 33px
  }

  &__actual-title {
    font-size 0.875em
    font-weight 700
    line-height 17px
  }

  &__actual-icon {
    cursor pointer

    .icon svg {
      width 12px
      height 12px
    }
  }

  &__right{
    width 100%
    max-width 572px
    background var(--pink)
    padding: 96px 62px
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    color var(--white)
    border-radius: 0 10px 10px 0;
    z-index 2
    +below(1235px) {
      padding 60px 62px
      max-width 100%
    }
    +below(680px) {
      padding 30px 30px
      max-width 100%
    }
    +below(363px) {
      gap 10px
      padding 20px 20px
    }

    &-title {
      +below(340px) {
        font-size 0.875em
      }
    }
  }

  &__timer{
    display flex
    gap 34px
    justify-content space-between
    width 100%
    +below(1235px) {
      max-width 572px
    }
    +below(540px) {
      gap 20px
    }
    +below(420px) {
      gap 10px
    }

    &-item {
      display flex
      flex-direction column
      align-items center
    }
  }

  &__dotts{
    padding-top: 35px
    +below(363px) {
      padding-top 25px
    }
    +below(340px) {
      padding-top 20px
    }
  }

  &__number {
    font-family: 'Manrope' !important;
    font-weight: 700;
    font-size: 3.75em;
    line-height: 140%;
    +below(680px) {
      font-size 3.5em
    }
    +below(425px) {
      font-size 3em
    }
    +below(363px) {
      font-size 2.75em
    }
    +below(340px) {
      font-size 2.5em
    }
    +below(320px) {
      font-size 2.25em
    }
  }

  &__text {
    +below(363px) {
      font-size 0.875em
    }
  }
}
</style>

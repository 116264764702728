<template>
  <section v-if="data && data.length" class="course-program container">
    <h2>Программа курса</h2>
    <div class="course-program__list">
      <div v-for="(item, i) in data" :key="i" class="course-program__item">
        <span class="course-program__item-title">{{ item.title }}</span>
        <span class="course-program__item-subtitle">{{ item.description }}</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CourseProgramComponent",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="stylus">
.course-program {
  display flex
  flex-direction column
  gap 60px
  padding 160px 0
  +below(1350px) {
    padding 160px 20px
  }
  +below(970px) {
    padding 100px 10px
  }

  &__list {
    display flex
    flex-direction column
  }

  &__item {
    display flex
    flex-direction column
    gap 15px
    padding 20px 0 10px 0
    border-bottom 1px solid var(--gray-dark)

    &-title {
      font-size 1.25em
      font-weight 600
      line-height 25px
    }

    &-subtitle {
      font-weight 600
      line-height 21px
      max-width 836px
    }
  }
}
</style>

<template>
  <div class="consultation-button" @click="openModal">
    <IconComponent name="phone" />
    <div class="consultation-button__text">
      <span>Консультация</span>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ConsultationModal from "components/modals/components/ConsultationModal.vue";
export default {
  name: "ConsultationButton",
  components: {
    IconComponent,
  },
  methods: {
    openModal() {
      this.$store.state._modals.push({
        component: ConsultationModal,
      });
    },
  },
};
</script>

<style lang="stylus">
.consultation-button {
  display flex
  align-items center
  cursor pointer
  padding 30px
  border none
  background var(--pink)
  color var(--white)
  font-size: 1em;
  border-radius 10px 0 0 10px;
  position: fixed;
  right 0
  top 250px
  z-index 10
  transition var(--transition)
  +below(1100px) {
    padding 30px
  }
  +below(700px) {
    display none
  }

  .icon svg {
    width 20px
    height 20px
    fill var(--white)
  }

  &__text {
    width 0
    visibility hidden
    opacity 0
    //transition var(--transition)
  }

  &:hover {
    //padding 30px 35px 30px 30px

    .consultation-button__text {
      width 100%
      margin-left 20px
      visibility visible
      opacity 1
    }
  }
}
</style>

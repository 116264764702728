<template>
  <main class="catalog">
    <CatalogBannerComponent :data="course" />
    <CatalogAdvantagesComponent :advantages="course.advantage" />
    <CatalogPromoComponent v-if="course.banners[0]" :data="course.banners[0]" :tariffs="course?.tariff" />
    <CourseProgramComponent :data="course?.course_program" />
    <FitComponent :data="course?.fit" />
    <LessonVideoComponent
      :data="course.video_file"
      :video_title="course.video_title"
      :video_description="course.video_description"
    />
    <ProblemBannerComponent :data="course" />
    <StudyIconComponent />
    <CatalogTeachersComponent
      :teachers="teachers.data"
      :total="teachers.paginatorInfo.total"
      @hide="hideTeachers"
      @show="showTeachers"
    />
    <CatalogTariffsComponent :data="course?.tariff" />
    <ReviewsComponent title="Отзывы о курсе" :data="reviews" />
    <AboutComponent :data="course" />
    <FaqComponent :data="course?.course_question" />
  </main>
</template>

<script>
import FaqComponent from "components/FaqComponent.vue";
import CourseProgramComponent from "../components/CourseProgramComponent.vue";
import ReviewsComponent from "components/ReviewsComponent.vue";
import ProblemBannerComponent from "../components/ProblemBannerComponent.vue";
import FitComponent from "../components/FitComponent.vue";
import LessonVideoComponent from "../components/LessonVideoComponent.vue";
import StudyIconComponent from "../components/StudyIconComponent.vue";
import AboutComponent from "../components/AboutComponent.vue";
import CatalogBannerComponent from "../components/CatalogBannerComponent.vue";
import CatalogTariffsComponent from "../components/CatalogTariffsComponent.vue";
import CatalogPromoComponent from "../components/CatalogPromoComponent.vue";
import CATALOG_OPEN from "gql/pages/catalog_open.graphql";
import CatalogAdvantagesComponent from "../components/CatalogAdvantagesComponent.vue";
import CatalogTeachersComponent from "../components/CatalogTeachersComponent.vue";
import TEACHERS from "gql/queries/teachers.graphql";

export default {
  name: "CatalogItemPage",
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: CATALOG_OPEN,
        variables: {
          first: parseInt(5),
          id: parseInt(route.params.id),
          course_id: parseInt(route.params.id),
        },
        context: {
          headers: {
            Authorization: "Bearer " + store.state.apollo_token,
          },
        },
      })
      .then(({ data }) => {
        store.state.courses_item = data.courses_item;
        store.state.tariffs = data.courses_item.tariff;
        store.state.courses_item.teachers = data.teachers_paginate;
        store.state.certificates = data.certificates;
        store.state.info = data.feedback;
        store.state.catalog_page.reviews = data.reviews_paginate;
      });
  },
  methods: {
    async hideTeachers() {
      await this.$apolloProvider.defaultClient
        .query({
          query: TEACHERS,
          variables: {
            course_id: parseInt(this.$route.params.id),
            first: parseInt(5),
          },
        })
        .then(({ data }) => {
          this.$store.state.courses_item.teachers = data.teachers_paginate;
        });
    },
    async showTeachers() {
      await this.$apolloProvider.defaultClient
        .query({
          query: TEACHERS,
          variables: {
            course_id: parseInt(this.$route.params.id),
            first: null,
          },
        })
        .then(({ data }) => {
          this.$store.state.courses_item.teachers = data.teachers_paginate;
        });
    },
  },
  computed: {
    course() {
      return this.$store.state.courses_item;
    },
    reviews() {
      return this.$store.state.catalog_page.reviews.data;
    },
    teachers() {
      return this.$store.state.courses_item.teachers;
    },
  },
  metaInfo() {
    let title = this.$store.state.courses_item.title;
    let description = this.$store.state.courses_item.heading;
    return {
      title,
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: "",
        },
        {
          vmid: "description",
          name: "description",
          content: description,
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: description,
        },
      ],
    };
  },
  components: {
    CatalogTeachersComponent,
    CatalogAdvantagesComponent,
    CatalogPromoComponent,
    CatalogTariffsComponent,
    CatalogBannerComponent,
    AboutComponent,
    StudyIconComponent,
    LessonVideoComponent,
    FitComponent,
    ProblemBannerComponent,
    ReviewsComponent,
    CourseProgramComponent,
    FaqComponent,
  },
};
</script>

<style lang="stylus">
.catalog {
  padding-top: 110px
  & .faq {
    padding 160px 0 120px 0
    +below(1350px) {
      padding 160px 0 75px 20px
    }
    +below(970px) {
      padding 100px 0 75px 10px
    }
    //+below(700px) {
    //  padding-bottom 40px
    //}
  }
  & .home-review {
    padding 160px 0 75px 0
    +below(1350px) {
      padding 160px 0 75px 20px
    }
    +below(970px) {
      padding 100px 0 75px 10px
    }
  }
}
</style>

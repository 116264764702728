<template>
  <div class="catalog-advantages container" v-if="advantages">
    <h2>Преимущества</h2>
    <div class="catalog-advantages__content">
      <div class="catalog-advantages__item" v-for="(item, i) in advantages" :key="i">
        <h3 class="catalog-advantages__number">0{{ i + 1 }}</h3>
        <div class="catalog-advantages__text">
          <h4 class="catalog-advantages__title">{{ item.title }}</h4>
          <span class="catalog-advantages__subtitle">{{ item.description }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CatalogAdvantagesComponent",
  props: {
    advantages: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="stylus">
&.catalog-advantages {
  display flex
  flex-direction column
  gap 60px
  padding-top 100px
  +below(1350px) {
    padding 60px 20px 0 20px
  }
  +below(910px) {
    padding 60px 10px 0 10px
  }

  &__content {
    display grid
    grid-template-columns repeat(2, 1fr)
    grid-gap 30px
    +below(650px) {
      display flex
      flex-direction column
      gap 30px
    }
  }

  &__item {
    display flex
    gap 10px
    +below(1130px) {
      flex-direction column
      //align-items flex-start
    }
  }

  &__number {
    font-size 6.25em
    font-weight 500
    line-height 81px
    margin-bottom 0
    +below(500px) {
      font-size 5em
    }
    +below(425px) {
      font-size 5.625em
    }
  }

  &__text {
    display flex
    flex-direction column
    gap 10px
    max-width 400px
    //word-break break-all
  }

  &__title {
    font-size 1.25em
    line-height 30px
    font-weight 700
    +below(500px) {
      font-size 1em
      line-height 100%
    }
    +below(425px) {
      font-size 1.25em
      line-height 30px
    }
  }

  &__subtitle {
    //display: -webkit-box;
    //overflow: hidden;
    //-webkit-box-orient: vertical;
    //-webkit-line-clamp: 2;
    //line-clamp: 2;
    +below(500px) {
      font-size 0.875em
    }
    +below(425px) {
      font-size 1em
    }
  }
}
</style>
